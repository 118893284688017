<template>
  <v-main class="natural lighten-3 pt-10">
    <v-container class="pt-15"  fluid>
      <v-row class="pt-10">
          <v-col cols="12">
        <BookingSelectList v-if="departmentList.choices.length > 0" v-bind="departmentList" v-model="department"/>
          </v-col>
        <v-col cols="12" >
          <BookingSlideItem v-if="doctorList.choices.length > 0" v-bind="doctorList" v-model="doctor"/>
        </v-col>
          <v-col cols="12" >
      <BookingSelectTwoColumn v-if="timeSlotList.choices.length > 0" v-bind="timeSlotList" v-model="timeSlot"/>
          </v-col>
               <v-col cols="12" >
      <TextArea :title="$t('booking.cc')" v-model="cc" />
          </v-col>
           
      </v-row>
    </v-container>
     <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-footer>
      <v-row>
   <v-col cols="12" >
   <v-btn :disabled="!timeSlot" block large color="primary text-title-2" @click="createAppointment()">ยืนยัน</v-btn>
          </v-col>
      </v-row>
    </v-footer>
  </v-main>
</template>

<script>

import BookingSelectList from "@/components/booking/BookingSelectList";
import BookingSelectTwoColumn from "@/components/booking/BookingSelectTwoColumn";
import BookingSlideItem from "@/components/booking/BookingSlideItem";
import TextArea from "@/components/form/TextArea"
import { getShifts, postShifts } from "@/api/cms"
import { mapFields } from "vuex-map-fields";

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByDes = groupBy('user_group_thai')
const groupByDesEN = groupBy('user_group_des')
const groupByFullname = groupBy('user_fullname')
const groupByFullnameEn = groupBy('user_engname')
export default {
  name: 'BookingView',
  components: {
      BookingSelectList,
      BookingSelectTwoColumn,
      TextArea,
      BookingSlideItem
  },
  computed: {
    ...mapFields(['visit'])
  },
  data() {
    return {
      cc: '',
      loading: false,
      items: [],
      value: null,
      shift: null,
      userGroupList: '',
      department: null,
      departmentList: {
          title: this.$t('booking.department'),
          choices: []
      },
      timeSlotList: {
          title: this.$t('booking.selectDate'),
          choices: []
      },
      timeSlot: null,
      doctor: {
             index: null,
             active: null,
             title: null,
             value: null
            },
      doctorList: {
         title: this.$t('booking.selectDoctor'),
         choices: []
      }
    }
  },
  watch: {
    department(val) {
        if (!val) return
        let choices = []
        if (this.$i18n.locale === 'th') {
          Object.keys(groupByFullname(this.shift[val.value])).map(item=> {
            choices.push({text: item,value: item})
          })
        }else{
          Object.keys(groupByFullnameEn(this.shift[val.value])).map(item=> {
            choices.push({text: item,value: item})
          })
        }

        if(this.visit) {
          this.timeSlotList.choices = []
          let index = choices.indexOf(choices.filter(item=> item.text.indexOf(this.visit.user_fullname) > -1)[0])
          if(index !== -1) {
            this.doctor = choices.filter(item=> item.text.indexOf(this.visit.user_fullname) > -1)[0]
          }else{
            this.doctor = null
          }
          this.timeSlot = null
          this.doctorList.choices = choices
        } else {
          this.timeSlotList.choices = []
          this.doctor = null
          this.timeSlot = null
          this.doctorList.choices = choices
        }
    },
    doctor(val) {
      if (!val) return
      let choices = []
      // console.log(this.shift[this.department.value])

      if (this.$i18n.locale === 'th') {
        groupByFullname(this.shift[this.department.value])[val.value].map(item=> {
        let slot = this.$dayjs(item.ot_st,'DD/MM/YYYY').local(this.$i18n.locale).format('DD MMMM YYYY') + ' ' + item.start_time
        choices.push({text: slot ,value: item})
      })
      } else {
        groupByFullnameEn(this.shift[this.department.value])[val.value].map(item=> {
        let slot = this.$dayjs(item.ot_st,'DD/MM/YYYY').local(this.$i18n.locale).format('DD MMMM YYYY') + ' ' + item.start_time
        choices.push({text: slot ,value: item})
      })
      }
    


      if (this.visit) {
        this.shift[this.visit.user_group_thai].map(item => {
          item.per_date = item.ot_st + ' ' + item.start_time
        })
        let slot = this.shift[this.visit.user_group_thai].filter(item=> this.$dayjs(item.per_date).unix() === this.$dayjs(this.visit.per_date).unix())
        if (slot.length > 0) {
            this.timeSlot = {
              value: slot[0]
            }
        }else{
            this.timeSlot = null
        }
        this.timeSlotList.choices = choices
      } else {
        this.timeSlot = null
        this.timeSlotList.choices = choices
      }
    }
  },
  methods: {
    createAppointment() {
      if (this.timeSlot) {
        if(!this.visit) {
        this.loading = true
       
        postShifts({
          ot_id: this.timeSlot.value.ot_id,
          cc: this.cc,
          is_tele: this.timeSlot.value.is_tele,
          visitId: "0"
        }, () => {
          this.loading = false
          this.$router.replace({name: 'appointmentsuccess', params: { userFullname: this.timeSlot.value.user_fullname, perDate:  this.timeSlot.value.ot_st + ' ' + this.timeSlot.value.start_time,department: this.timeSlot.value.user_group_thai}})
        }, error=> {
          console.log(error)
          this.loading = false
        })
        }else{
          this.loading = true
          postShifts({
          ot_id: this.timeSlot.value.ot_id,
          cc: this.cc,
          is_tele: this.timeSlot.value.is_tele,
          visitId: this.visit.visitId
        }, () => {
          this.loading = false
          this.$router.replace({name: 'appointmentsuccess', params: { userFullname: this.timeSlot.value.user_fullname, perDate:  this.timeSlot.value.ot_st + ' ' + this.timeSlot.value.start_time,department: this.timeSlot.value.user_group_thai}})
        }, error=> {
          console.log(error)
          this.loading = false
        })

//           this.visit.user_id = this.timeSlot.value.user_id
//           this.visit.user_fullname = this.timeSlot.value.user_fullname
//           this.visit.user_group = this.timeSlot.value.user_group
//           this.visit.user_group_des = this.timeSlot.value.user_group_des
//           this.visit.user_group_thai = this.timeSlot.value.user_group_thai
//           this.visit.per_date = this.timeSlot.value.ot_st + ' ' + this.timeSlot.value.start_time
//           this.visit.is_tele = this.timeSlot.value.is_tele
//           this.visit.cc_note = this.cc
//           postCMSVisits(this.visit,message=> {
//            if(message.data.code === 1) {
//               this.$router.replace({name: 'appointmentsuccess', params: { userFullname: this.timeSlot.value.user_fullname, perDate:  this.timeSlot.value.ot_st + ' ' + this.timeSlot.value.start_time,department: this.timeSlot.value.user_group_thai}})
//           }else{
//             // Fails
//           }
//           this.loading = false
//           },error=>{
// console.log(error)
//           this.loading = false
//           })
        }
      }
    },
    async fetchData() {
        this.role = this.$offlineStorage.get('role')
        // const site = this.$offlineStorage.get("site");
        this.loading = true
        try {
          // const lab = await getLab()
          // console.log(lab.data)

          // const shift = await getCMSShifts({groupId: site.groupId,user_group_list: this.userGroupList,start_date: this.$dayjs(new Date()).format("YYYY-MM-DD")})
          const shift = await getShifts()
          if (shift.data) {
            // Assign all shift //v-if="$i18n.locale === 'th'"
            this.shift = (this.$i18n.locale === 'th') ? groupByDes(shift.data) : groupByDesEN(shift.data)
             // Assign department list
            let department = {title: this.$t('booking.department'), choices:[]}
            if (this.$i18n.locale === 'th') {
              Object.keys(groupByDes(shift.data)).map((item)=> {
              department.choices.push({text: item, value: item})
            })
            } else {
              Object.keys(groupByDesEN(shift.data)).map((item)=> {
              department.choices.push({text: item, value: item})
            })
            }

            this.departmentList = department
            // console.log(department)
            // console.log(Object.keys(groupByDoctorType(shift.data.result)))
          }
           if(this.visit) {
            //  console.log('edited mode')
            //  console.log(this.visit)
             this.department = {
               active: true,
               title: this.visit.user_group_thai,
               value: this.visit.user_group_thai
             }
             this.cc = this.visit.cc_note
             
           }
           this.loading = false
        }catch (error) {
          console.log(error)
           this.loading = false
        }
    }
  },
   created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
   }
};
</script>

<style>
</style>