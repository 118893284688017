<template>

     <div class="pt-3 pb-3">
               <label class="text-title-3">  
    {{ title }} <span v-if="error" class="error--text text-title-4"> *กรุณาเลือกคำตอบ</span></label>
            <v-slide-group
        v-bind:value="choices.indexOf(value)"
        @change="togle"
        class="pa-0 ma-0"
        center-active
      >
        <v-slide-item
          v-for="(item, index) in choices" 
          :key="index"
          v-slot="{ active, toggle }"
        >
          <v-card
            class="ma-2"
            height="180"
            width="120"
            @click="toggle"
          >
          <v-card-text class="text-center fill-height">
          <v-avatar size="90" :style="{
                    border: active? '2px solid var(--v-primary-base) !important' : '2px solid var(--v-natural-darken-1) !important'
                    }">
              <v-img contain :src="require('@/assets/whiteavatar.svg')"></v-img>
          </v-avatar>
 <div class="text-center font-weight-bold primary--text">
       {{item.text}}
      </div>
          </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
            </div>
</template>

<script>

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
  },
  props: ["title","value","error","choices"],
  data() {
    return {
      items: [],
      model: null
    }
  },
  methods: {
    togle(val) {
      this.$emit('change',this.choices[val])
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>