<template>
  <v-card  @click="onClick"
            :style="{
                    border: active? '2px solid var(--v-primary-base) !important' : '2px solid var(--v-natural-darken-1) !important'
                    }">
       <v-list >
          <v-list-item >
              <v-list-item-content>
                <v-list-item-title class="primary--text">{{title}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                 :color="active? 'primary': 'natural darken-1'"
                >
                  mdi-check-circle
                </v-icon>
              </v-list-item-action>
          </v-list-item>
       </v-list>
  </v-card>
</template>

<script>
export default {
  model: {
    prop: 'active',
    event: 'change'
  },
  props: ['title','active','index'],
  methods: {
    onClick() {
      this.$emit('update:active', true)
      this.$emit('togle',this.index)
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }}
};
</script>

<style lang="scss" scoped>
 .v-btn--outlined {
    border: 2px solid currentColor !important;
  }
  ::v-deep .v-btn__content { width: 100%; white-space: normal; text-align: left; }
</style>
